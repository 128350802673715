<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center" v-if="account">{{ account.name }}</h1>
                <!-- <p class="text-caption text-center">DNS</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pb-2">
                        <v-app-bar color="teal darken-2" dark flat dense>
                            <v-app-bar-title>Account overview</v-app-bar-title>
                            <v-spacer/>
                            <v-menu offset-y bottom left open-on-click open-on-hover close-delay="100">
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" color="white">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']"/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'trash']" class="teal--text text--darken-2" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Delete...
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <!-- <v-list-item @click="editEmailDispatchDeliveryDateDialog = true">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'calendar-edit']" class="teal--text text--darken-2" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Reschedule...
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item> -->
                                </v-list>
                            </v-menu>

                        </v-app-bar>

                        <v-row no-gutters class="ma-4">
                            <v-col cols="12">
                                <p class="text-overline mb-0 text-center">Data</p>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="ma-4">
                            <v-col cols="12" sm="3">
                                <NavCard label="Contacts" :icon="['fas', 'address-card']" :to="{ name: 'account-search-email-contact', params: { accountId: this.$route.params.accountId } }" tooltip="A person who interacted with your business in some way. Not necessarily subscribed to mailing lists."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Audiences" :icon="['fas', 'users']" :to="{ name: 'account-search-email-audience', params: { accountId: this.$route.params.accountId } }" tooltip="Also called a smart mailing list, keeps track of people who have something in common that you want to address with email communication, or that affects which messages you send them. Email contacts are automatically included in an email audience based on criteria you define."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Templates" :icon="['fas', 'file-code']" :to="{ name: 'account-search-email-template', params: { accountId: this.$route.params.accountId } }" tooltip="All email is generated from a template, so you can personalize the message for each recipient."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Dispatches" :icon="['fas', 'paper-plane']" :to="{ name: 'account-search-email-dispatch', params: { accountId: this.$route.params.accountId } }" tooltip="An immediate or scheduled delivery of a specified email template to an email audience."/>
                            </v-col>
                        </v-row>

                        <v-divider/>

                        <v-row no-gutters class="ma-4">
                            <v-col cols="12">
                                <p class="text-overline mb-0 text-center">Settings</p>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="ma-4">
                            <v-col cols="12" sm="3">
                                <NavCard label="BrandProfile" :icon="['fas', 'registered']" :to="{ name: 'account-dashboard-brandprofile', params: { accountId: this.$route.params.accountId } }" tooltip="A BrandProfile stores your brand name, images, verified domains, email addresses, postal addresses, and more."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Topics" :icon="['fas', 'comment-alt-exclamation']" :to="{ name: 'account-search-topic', params: { accountId: this.$route.params.accountId } }" tooltip="Used to allow contacts to opt-in to some communications but not others, for example &quote;News&quote; but not &quote;Trivia&quote;."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Tags" :icon="['fas', 'tag']" :to="{ name: 'account-search-tag', params: { accountId: this.$route.params.accountId } }" tooltip="Used to create smart mailing lists, such as &quote;all contacts who clicked on the demo button&quote;."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Files" :icon="['fas', 'file-alt']" :to="{ name: 'account-search-file', params: { accountId: this.$route.params.accountId } }" tooltip="Used to import and export data from Emplus."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Domains" :icon="['fas', 'globe']" :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }" tooltip="All email is sent from a domain. Anti-spam settings reference this domain.<"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="From" :icon="['fas', 'at']" :to="{ name: 'account-search-email-origin', params: { accountId: this.$route.params.accountId } }" tooltip="The &quote;from&quote; address of your emails."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Reply-To" :icon="['fas', 'inbox-in']" :to="{ name: 'account-search-email-replyto', params: { accountId: this.$route.params.accountId } }" tooltip="The &quote;reply-to&quote; address of your emails."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Locations" :icon="['fas', 'map-marker']" :to="{ name: 'account-search-postal-address', params: { accountId: this.$route.params.accountId } }" tooltip="Where people can reach you by postal mail."/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <NavCard label="Transports" :icon="['fas', 'bolt']" :to="{ name: 'account-search-outbound-email-transport', params: { accountId: this.$route.params.accountId } }" tooltip="How we deliver email on your behalf."/>
                            </v-col>
                            <!-- <v-col cols="12" sm="3">
                                <NavCard label="API Tokens" :icon="['fas', 'key']" :to="{ name: 'account-search-api-token', params: { accountId: this.$route.params.accountId } }" tooltip="How we deliver email on your behalf."/>
                            </v-col> -->
                        </v-row>

                        <!-- <v-card-text> -->

                        <!-- <p class="text-overline mb-0 mt-2">Email contacts</p>
                        <p class="text-caption mb-0 mt-0">A person who interacted with your business in some way. Not necessarily subscribed to mailing lists. Helps you track effectiveness of your marketing campaign over time.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-contact', params: { accountId: this.$route.params.accountId } }">See all email contacts</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Email audiences</p>
                        <p class="text-caption mb-0 mt-0">Also called a smart mailing list, keeps track of people who have something in common that you want to address with email communication, or that affects which messages you send them. Email contacts are automatically included in an email audience based on criteria you define.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-audience', params: { accountId: this.$route.params.accountId } }">See all email audiences</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Email templates</p>
                        <p class="text-caption mb-0 mt-0">All email is generated from a template, so you can personalize the message for each recipient.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-template', params: { accountId: this.$route.params.accountId } }">See all email templates</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Email lists</p>
                        <p class="text-caption mb-0 mt-0">A list of contacts to receive emails.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-list', params: { accountId: this.$route.params.accountId } }">See all email lists</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Email dispatches</p>
                        <p class="text-caption mb-0 mt-0">An immediate or scheduled delivery of a specified email template to an email audience.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-dispatch', params: { accountId: this.$route.params.accountId } }">See all email dispatches</router-link>
                        </p> -->

                        <!-- marketing, subscribe, opt-in, etc. -->

                        <!-- <p class="text-overline mb-0 mt-8">Topics</p>
                        <p class="text-caption mb-0 mt-0">Used to allow contacts to opt-in to some communications but not others, for example "News" but not "Trivia".</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-topic', params: { accountId: this.$route.params.accountId } }">See all topics</router-link>
                        </p> -->
                        <!-- <p class="text-overline mb-0 mt-8">Tags</p>
                        <p class="text-caption mb-0 mt-0">Used to create smart mailing lists, such as "all contacts who clicked on the demo button".</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-tag', params: { accountId: this.$route.params.accountId } }">See all tags</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Files</p>
                        <p class="text-caption mb-0 mt-0">Used to import and export data from Emplus.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-file', params: { accountId: this.$route.params.accountId } }">See all files</router-link>
                        </p> -->

                        <!-- account setup -->
                        <!-- <p class="text-overline mb-0 mt-8">BrandProfiles</p>
                        <p class="text-caption mb-0 mt-0">A BrandProfile stores your brand name, images, verified domains, email addresses, postal addresses, and more.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-brandprofile', params: { accountId: this.$route.params.accountId } }">See all BrandProfiles</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Domains</p>
                        <p class="text-caption mb-0 mt-0">All email is sent from a domain. Anti-spam settings reference this domain.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">See all domains</router-link>
                        </p> -->
                        <!-- <p class="text-overline mb-0 mt-8">Email Outbound Mailboxes</p>
                        <p class="text-caption mb-0 mt-0">The "from" address of your emails.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-origin', params: { accountId: this.$route.params.accountId } }">See all email origins</router-link>
                        </p> -->
                        <!-- <p class="text-overline mb-0 mt-8">Email Inbound Mailboxes</p>
                        <p class="text-caption mb-0 mt-0">The "reply-to" address of your emails.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-email-replyto', params: { accountId: this.$route.params.accountId } }">See all email reply-to addresses</router-link>
                        </p> -->

                        <!-- <p class="text-overline mb-0 mt-8">Postal Addresses</p>
                        <p class="text-caption mb-0 mt-0">Where people can reach you by postal mail.</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-postal-address', params: { accountId: this.$route.params.accountId } }">See all postal addresses</router-link>
                        </p> -->
                        <!-- TODO: REMOVE OLD LINKS FROM LIBERTYCLOUD BELOW -->
                        <!-- <p class="text-overline mb-0 mt-8">Websites</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-website', params: { accountId: this.$route.params.accountId } }">See all websites</router-link>
                        </p>
                        <p class="text-overline mb-0 mt-8">Users</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">See all users</router-link>
                        </p>
                        <p class="text-overline mb-0 mt-8">Volumes</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-search-volume', params: { accountId: this.$route.params.accountId } }">See all storage volumes</router-link>
                        </p> -->
                        <!-- </v-card-text> -->

                        <!-- <v-divider/> -->

                        <!-- <v-card-text>
                        <p class="text-overline mb-0 mt-2">Other</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                        </p>
                        </v-card-text> -->
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import NavCard from '@/components/account-dashboard/NavCard.vue';

export default {
    components: {
        NavCard,
    },
    data: () => ({
        account: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        name() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
