<template>
    <v-card elevation="2" class="mx-2 my-2 pt-2" :to="to">
        <p class="mb-0 text-center"><font-awesome-icon :icon="icon" style="font-size: 20px;" fixed-width/></p>
        <p class="mb-0 text-center text-overline" v-text="label"></p>
    </v-card>
</template>
<script>
export default {
    props: ['to', 'icon', 'label'],
};
</script>
